@import '/src/assets/scss/1-variables/app';

.card.trailerChanges-trip__card {
  margin-top: 1rem;
}

.trailerChanges-card-body {
  background-color: $white;
}

.trailerChanges__move-shipment {
  display: flex;
  justify-content: center;
  background-color: $tc-shipment;
  margin: .25rem;
  padding: .25rem;
  border-radius: 4px;
}

.trailerChanges-btn {
  cursor: pointer;
  color: $board-button-color;
}


.trailerChanges-message {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  color: $gray-700;
}

.trailerChanges-collapse-btn-wrapper {
  position: relative;
  height: 40px;
  width: 100px;
  padding: 0;
}
