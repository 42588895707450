.eta-table-header { 
  font-weight: bold;
  border-bottom: 0.125rem solid;
  padding-bottom: 1rem;
  opacity: 0.8;
  div.row {
    display: grid;
    margin: auto;
  }
}

.eta-table-data {
  display: grid;
  margin: auto;
  div.row {
      display: flex;
      justify-content: space-evenly;
      padding-top: 1.2rem;
      min-height: 50px;
      align-items: center;
  }
}
