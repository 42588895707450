@import '../../../assets/scss/1-variables/app';

.caret {
  .dropdown-toggle::after {
    display: none;
  }
}

.shipment-card-col {
  margin-bottom: 0.5rem;
}

.shipment-status {
  margin-bottom: 0.5rem;
  text-align: center;
  font-weight: bolder;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  &.invoiced {
    color: $success;
  }
  &.booked {
    color: $info;
  }
  &.missing-info {
    color: $danger;
  }
  &.completed {
    color: $primary;
  }
  &.training {
    color: $warning;
  }
  span {
    border: 0.0625rem solid ;
    border-radius: 0.125rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

}

.bold-text {
  font-weight: 700;
  color: $black;
}

.shipment-card-icon-container {
  margin-left: 10px;
}

.shipmentDetails_attachment-section {
  min-width: 20rem;
}

.document-list {
  width: 95%
}

.attachment-icon-wrapper {
  cursor: pointer;
  margin-left: 2px;
  right: 20px;
}

.attachment-add-icon {
  margin-left: 4px;
}

.attachment-delete-icon {
  color: $danger;
  margin-left: 4px;
}

